
import Sortable from 'sortablejs';
import { tableConfigs } from '../assets/TableConfigs';

import GenericTableCalculator from './GenericTableCalculator';

export default class PositionView { 

    constructor(url, containerId, headers, types, configKey) {
        this.url = url;
        this.containerId = containerId;
        this.headers = headers;
        this.types = types;
        this.config = tableConfigs[configKey];
    }

    init() {
        this.loadData();
    }

    loadData() {
        this.fetchData().then(data => {
            this.instance = data.instance;
            this.data = data.combined_positions;
            this.renderTable();
            this.calculateTable();
        }).catch(error => {
            console.error('Error:', error);
        });
    } 

    async fetchData() {
        const response = await fetch(this.url, {
            headers: {
                'x-requested-with': 'XMLHttpRequest'
            },
            method: 'GET',
        });
        return response.json();
    }

    calculateTable() {
        const tableElement = document.getElementById('calculation-tbody');
        if (tableElement) {
            const calculator = new GenericTableCalculator(tableElement.id, this.config);
            calculator.init();
        }
    }

    renderTable() {
        const container = document.getElementById(this.containerId); 
        if (!container) return;


        const responsiveContainer = document.createElement('div');
        responsiveContainer.className = 'table-responsive';

        // table-responsive
        const table = document.createElement('table');
        table.className = 'table';
        const thead = document.createElement('thead');
        const tbody = document.createElement('tbody');
        tbody.id = 'calculation-tbody';
        table.id = 'sortable-table';

        const headerRow = document.createElement('tr'); 
        this.headers.forEach(header => {
            const th = document.createElement('th');
            th.textContent = header;
            headerRow.appendChild(th);
        });
        thead.appendChild(headerRow);

        this.data.forEach(item => {
            const row = document.createElement('tr');
            row.dataset.id = item.id;
            row.dataset.type = item.model_type;

            const typeConfig = this.types[`${item.model_type}_positions`];
            if (!typeConfig) {
                console.log(`${item.model_type}_positions`);
            }
            
            row.classList.add(typeConfig.rowClass || '');

            row.dataset.modelName = typeConfig['model_name'];
            row.dataset.appLabel = typeConfig['app_label'];

            let updateURLTemplate = urlMappings[typeConfig['app_label']][item.model_type];
             
            typeConfig.fields.forEach(field => {

                const cell = document.createElement('td');

                const colspan = typeConfig.colspans && typeConfig.colspans[field] ? typeConfig.colspans[field] : 1;
                cell.colSpan = colspan;

                if (field === 'id') {
                    const div = document.createElement('div');
                    div.className = `d-flex gap-3 align-items-center`;
                    
                    var updateURL = updateURLTemplate
                        .replace(/0/, item.id)
                        .replace(/0\//, this.instance + '/');
                    
                    const updateButton = document.createElement('div');
                    updateButton.className = `openModalServie update-${item.model_type} lock-class`;
                    updateButton.setAttribute('data-modal-url', updateURL);

                    const updateIcon = document.createElement('i');
                    updateIcon.className = `fa-regular fa-pen-to-square`;
                    updateButton.appendChild(updateIcon);
        
                    const deleteButton = document.createElement('div');
                    deleteButton.className = `delete-${item.model_type} delete-modal lock-class`;
                    const deleteIcon = document.createElement('i');
                    deleteIcon.className = `fa-solid fa-trash-can`;
                    deleteButton.appendChild(deleteIcon);

                    var deleteURLTemplate = urlMappings[typeConfig['app_label']][`${item.model_type}_delete`];
                    var deleteURL = deleteURLTemplate.replace(/0/, item.id);
                    deleteButton.setAttribute('data-url', deleteURL); 
                    deleteButton.setAttribute('data-id', item.id); 

                    div.appendChild(updateButton);
                    div.appendChild(deleteButton);
        
                    cell.appendChild(div);
                } else {
                    cell.textContent = item[field] !== undefined ? item[field] : '';
                }
                row.appendChild(cell);
            });
            tbody.appendChild(row);
        });

        table.appendChild(thead);
        table.appendChild(tbody);
        container.innerHTML = '';
        
        responsiveContainer.appendChild(table);
        container.innerHTML = '';
        container.appendChild(responsiveContainer);

        new Sortable(tbody, {
            animation: 150,
            onEnd: function () {
                const positions = [];
                tbody.querySelectorAll('tr').forEach((row, index) => {
                    positions.push({
                        id: row.dataset.id,
                        app_label: row.dataset.appLabel,
                        model_name: row.dataset.modelName,
                        position: index + 1
                    });
                });
                           
                fetch(UpdatePositions, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-requested-with': 'XMLHttpRequest',
                        'X-CSRFToken': document.querySelector('[name=csrfmiddlewaretoken]').value
                    },
                    body: JSON.stringify(positions)
                }).then(response => {
                    if (!response.ok) {
                        console.error('Fehler beim Speichern der Positionen.');
                    }
                }).catch(error => {
                    console.error('Fehler beim Speichern der Positionen:', error);
                });

            }
        });
    }
}
