// agriflow/customers/static/js/customers.js

import customersUpdate from './modles/customersUpdate'
import renderContact from './modles/renderContact'
import customersChangeHistory from './modles/customersChangeHistory'
import getNextNumerView from '../../../core/static/module/numberRange.js';
import customersCreate from './modles/customersCreate'

document.addEventListener('DOMContentLoaded', function () {
    const customerscreate = new customersCreate();
    const customersupdate = new customersUpdate();
    const rendercontact = new renderContact();
    const customerschangehistory = new customersChangeHistory();

    const createCustomerForm = document.getElementById('customerCreate');
    if(!createCustomerForm) {
        return;
    }
    getNextNumerView('KUN');
});