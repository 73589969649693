

import { BaseAjaxSave } from "../../../../assets/js/common/shared_service";

export default class InvoiceUpdate {

    constructor(){
        this.invoiceUpdateForm = document.querySelector('#inoicesUpdateForm');
        if(!this.invoiceUpdateForm) return;
        this.events();
    }

    events(){
        this.invoiceUpdateForm.addEventListener('submit', (event) => { 
            BaseAjaxSave(event);
        }); 
    }

}