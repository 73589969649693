// ArchiveModal.js
import AlertHandler from "../modules/alertHandler";

export default class ArchiveManager {
    constructor() {

    }

    toggleArchive(url) {
        const csrftoken = document.querySelector('[name=csrfmiddlewaretoken]').value;

        fetch(url, {
            method: 'POST',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRFToken': csrftoken,
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Netzwerkantwort war nicht ok');
            }
            return response.json();
        })
        .then(data => {
            if (data.success) {
                AlertHandler.showAlert(data.message, 'success');
                if (data.is_archived) {
                    archiveBtn.textContent = 'Dearchivieren';
                    if (!deleteBtn) {
                        const newDeleteBtn = document.createElement('a');
                        newDeleteBtn.href = `/offers/delete/${data.id}/`;
                        newDeleteBtn.className = 'btn btn-danger btn-sm';
                        newDeleteBtn.textContent = 'Löschen';
                        archiveBtn.parentNode.insertBefore(newDeleteBtn, archiveBtn);
                    }
                } else {
                    archiveBtn.textContent = 'Archivieren';
                    if (deleteBtn) {
                        deleteBtn.remove();
                    }
                }

            } else {
                console.error('Fehler:', data.error);
            }
        })
        .catch(error => {
            console.error('Fehler:', error);
        });
    }

}
