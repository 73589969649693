

export default class dataTableListView{

    constructor(){
        this.events();
    }

    events(){
        const roleTable = document.querySelector('#data-table');
        if(roleTable){
            this.FetchData();
            this.addFilters();
        }
    }

    getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }


    

    addFilters() {
        if (!filter_fields || !Array.isArray(filter_fields) || filter_fields.length === 0) {
            console.log('Keine Filter-Felder gefunden');
            return;
        }

        const filterContainer = document.createElement('div');
        filterContainer.className = 'filter-container d-flex flex-wrap align-items-center justify-content-start';

        filter_fields.forEach(filter => {
            /* const wrapper = document.createElement('div');
            wrapper.className = 'filter-wrapper mb-2';

            const label = document.createElement('label');
            label.htmlFor = `${filter.name}-filter`;
            label.className = 'form-label me-2';
            label.textContent = filter.label;

            const select = document.createElement('select');
            select.id = `${filter.name}-filter`;
            select.className = 'form-select'; */

            const wrapper = document.createElement('div');
            wrapper.className = 'filter-wrapper me-2 mb-2';

            const select = document.createElement('select');
            select.id = `${filter.name}-filter`;
            select.className = 'form-select form-select-sm';

            /* filter.choices.forEach((choice, index) => {
                const option = document.createElement('option');
                option.value = index === 0 ? '' : choice; // Leere Auswahl für "Alle anzeigen", sonst der eigentliche Wert
                option.textContent = choice;
                select.appendChild(option);
            }); */
            filter.choices.forEach(choice => {
                const option = document.createElement('option');
                option.value = choice;
                option.textContent = choice;
                select.appendChild(option);
            });

            /* wrapper.appendChild(label);
            wrapper.appendChild(select);
            filterContainer.appendChild(wrapper); */

            wrapper.appendChild(select);
            filterContainer.appendChild(wrapper);
        });

        const dataTable = document.querySelector('#data-table');
        if (dataTable) {
            dataTable.parentNode.insertBefore(filterContainer, dataTable);
        }
    }
        

    async FetchData(){
        try {

            const accessToken = this.getCookie('access_token');
            const response = await fetch(apiUrl, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (response.ok) {
                const data = await response.json();
                this.allPermissions = await this.fetchAllPermissions();
                var columns = fields.map(field => ({ data: field }));
                var availableHeight = $(window).height() - $('body').offset().top;
                function setTableHeight() {
                    $('#data-table').DataTable({
                        processing: true,
                        serverSide: true,
                        searching: true,
                        responsive: true,
                        paging: true,
                        pageLength: 50,
                        autoWidth: true,
                        fixedHeader: true,
                        scrollCollapse: true,
                        scrollX: '100%',
                        scrollY: availableHeight  - 300,
                        pageResize: true,
                        lengthMenu: [[25, 50, 100], [25, 50, 100]],
                        ajax: {
                            url: apiUrl,
                            dataSrc: function(json) {
                                if (json && json.results && Array.isArray(json.results)) {
                                    $('#totalRecords').text(json.count); 
                                    return json.results;
                                } else {
                                    $('#totalRecords').text(0);
                                    return [];
                                }
                            },
                            data: function(d) {
                                d.page = Math.ceil(d.start / d.length) + 1;
                                d.page_size = d.length;
                                d.searchValue = d.search.value;
       
                                if (d.order && d.order.length > 0) {
                                    d.ordering = d.columns[d.order[0].column].data;
                                    d.ordering_dir = d.order[0].dir;
                                }

                                $('#archive-filter').each(function() {
                                    const filterName = $(this).attr('id').replace('-filter', '');
                                    const filterValue = $(this).val();
                                    console.log(filterValue)
                                    if (filterValue !== 'Nur nicht archivierte') {
                                        d[filterName] = filterValue;
                                    }
                                });
                                return d;
                            },
                            error: function(xhr, errorType, exception) {
                                console.log('Fehler beim Laden der Daten:', exception);
                                return [];
                            }
                        },
                        columns: columns,
                        createdRow: function(row, data, dataIndex) {
                            const rowUrl = update_url.replace('0', data.id);
                            $(row).attr('onclick', 'window.location.href="' + rowUrl + '"');
                            $(row).css('cursor', 'pointer');
                        },
                        language: {
                            url: 'https://cdn.datatables.net/plug-ins/2.0.0/i18n/de-DE.json',
                            paginate: {
                                next: '<i class="fas fa-angle-right"></i>',
                                previous: '<i class="fas fa-angle-left"></i>',
                                first: '<i class="fas fa-angle-double-left"></i>',
                                last: '<i class="fas fa-angle-double-right"></i>'
                            }
                        },
                    });
                }


                setTableHeight();

                $(window).resize(function() {
                    $('#data-table').DataTable().columns.adjust().draw();
                });

                $('#archive-filter').on('change', function() {
                    $('#data-table').DataTable().columns.adjust().draw();
                });

            } else {
                console.error(`Failed to fetch data from ${apiUrl}:`, response.statusText);
            }


        } catch (error) {
            console.error('Error:', error);
        }
    }


    async fetchAllPermissions() {
        const accessToken = this.getCookie('access_token');
        
        /* if (!accessToken) {
            console.error('Kein Zugriffstoken vorhanden.');
            window.location.href = logoutView;
            return;
        } */

        const response = await fetch(apiUrl, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            }
        });
        if (response.ok) {
            return await response.json();
        } else {
            console.error('Failed to fetch permissions:', response.statusText);
            return [];
        }
    }

}