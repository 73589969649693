import  { ChangeHistory } from '../../../../assets/js/modules/changeHistory';


export default class serviceChangeHistory {
    constructor() {
        this.changeHistoryContainer = document.querySelector('#change-history-container-service');
        if(!this.changeHistoryContainer) return;
        this.event();
    }

    event() {
        if (this.changeHistoryContainer) {
            const modelName = 'service';
            const objectId = serviceId;
            const fieldsToShow = ['designation_number', 'designation', 'cost_per_unit', 'unit'];
            const fieldLabels = {
                designation_number: 'Bezeichnungsnummer',
                designation: 'Bezeichnung',
                cost_per_unit: 'Kosten pro Einheit',
                unit: 'Einheit',
            };
            const changeHistory = new ChangeHistory(modelName, objectId);
            changeHistory.renderChanges('change-history-container-service', fieldsToShow, fieldLabels);
        }
    }
}