

import machinerysCreate from './module/machinerysCreate';
import machinerysUpdate from './module/machinerysUpdate';
import machinerysChangeHistory from './module/machinerysChangeHistory';

import getNextNumerView from '../../../core/static/module/numberRange';


document.addEventListener('DOMContentLoaded', function () {
    const machineryscreate = new machinerysCreate();
    const machinerysupdate = new machinerysUpdate();
    const machineryschangehistory = new machinerysChangeHistory();
    
    // Nummer
    const machinerysCreateFrom = document.getElementById('machineryCreateForm');
    if(!machinerysCreateFrom) return;
    getNextNumerView('MAC');

}); 