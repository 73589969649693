
import { Modal } from 'bootstrap';
import AlertHandler from '../../../../assets/js/modules/alertHandler';

export default class EmailSend{
    
    constructor(){
        this.modal = null;
        this.events()
    }

    events() {
        var emailButtons = document.querySelectorAll('.email-button');
        if (!emailButtons) return;
        emailButtons.forEach((button) => {
            button.addEventListener('click', (event) => {
                event.preventDefault();
                const url = button.getAttribute('data-url');
                this.openEmailModal(url);
            });
        });

        document.addEventListener('click', (event) => {
            if (event.target.matches('.close-modal-button-email')) {
                this.closeEmailModal();
            }
        });
    }

    openEmailModal(url) {
        fetch(url)
            .then(response => response.json())
            .then(data => {
                if (data.html) {
                    document.querySelector('#emailModal .modal-body').innerHTML = data.html;
                    
                    if (data && data.title) {
                        const titleElement = document.querySelector('#emailModal .modal-title');
                        if (titleElement) {
                            titleElement.textContent = data.title || 'Titel'; 
                        }
                    }
                    this.modal = new Modal(document.getElementById('emailModal'));
                    this.modal.show();

                    if (data && data.html) {
                        const contentElement = document.querySelector('#emailModal .modal-body');
                        if (contentElement) {
                            const form = contentElement.querySelector('form'); 
                            form.addEventListener('submit', this.submitForm.bind(this));
                        }
                    }

                } else {
                    console.error('Fehler beim Laden des Modalinhalts');
                }
            })
            .catch(error => {
                console.error('Fehler:', error);
            });
    }

    closeEmailModal() {
        if (this.modal) {
            this.modal.hide();
        }
    }

    submitForm(event){
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);
        const action = form.action;
        const method = form.method;
        const csrfToken = form.querySelector('input[name="csrfmiddlewaretoken"]').value; 
        fetch(action, {
            method: method,
            body: formData,
            headers: {
                'X-CSRFToken': csrfToken,
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                console.log('E-Mail erfolgreich gesendet');
                AlertHandler.showAlert(data.message, 'success');
                this.closeEmailModal();
            } else {
                console.error('Fehler beim Senden der E-Mail:', data.error);
                const allErrorMessages = Object.values(data.errors).flat().join(', ');
                AlertHandler.showAlert(allErrorMessages, 'danger');
            }
        })
        .catch(error => {
            console.error('Fehler:', error);
        });
    }

}