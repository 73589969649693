export default class FormLockManager {
    constructor(formId, buttonClass, url) {
        this.form = document.getElementById(formId);
        this.buttonClass = buttonClass;
        this.lastStatus = null;
        this.url = url;

        if (!this.form) return;
        
        this.statusField = this.form.querySelector('[name="status"]');
        if (this.statusField) {
            this.lastStatus = this.statusField.value;
            
            setTimeout(() => {
                this.init();
            }, 900);
        }
    }

    init() {
        this.checkFormLock();
        this.statusField.addEventListener('change', () => {
            setTimeout(() => {
                this.checkFormLock();
                this.resetOfferData();
            }, 400);

                if (this.statusField.value === 'Abgebrochen') {
                    setTimeout(() => {
                        this.unlockForm();
                        this.resetOfferData();
                    }, 100);
                }
            
        });
    }

    checkFormLock() {
        if (this.statusField.value === 'Bestätigt' || this.statusField.value === 'Abgebrochen') {
            this.lockForm();
        } else {
            this.unlockForm();
        }
    }

    lockForm() {

        Array.from(this.statusField.options).forEach(option => {
            if (option.value !== 'Abgebrochen' && option.value !== 'Bestätigt') {
                option.remove();
            }
        });

        if(this.statusField.value === 'Abgebrochen'){
            Array.from(this.statusField.options).forEach(option => {
                if (option.value !== 'Abgebrochen') {
                    option.remove();
                }
            });
        }
        

        const customerSelect = $('#id_customer');
        if (customerSelect.length) {
            customerSelect.prop('disabled', true);  // Wichtig!
            customerSelect.addClass('readonly');
            customerSelect.css('pointer-events', 'none');
            customerSelect.trigger('change.select2');  
        }

        this.form.querySelectorAll('input, select, textarea').forEach(element => {
            if (element !== this.statusField) {
                element.setAttribute('readonly', true);
                element.style.pointerEvents = 'none';
            }
        });
        
        const submitBtn = this.form.querySelector('input[type="submit"]');
        if (submitBtn) submitBtn.classList.add('disabled');

        if (this.buttonClass) {
            document.querySelectorAll('.' + this.buttonClass).forEach(button => {
                button.classList.add('disabled');
                button.style.pointerEvents = 'none';
            });
        }
    }

    unlockForm() {
        const customerSelect = $('#id_customer');
        if (customerSelect.length) {
            customerSelect.prop('disabled', false);  // Wichtig!
            customerSelect.removeClass('readonly');
            customerSelect.css('pointer-events', 'auto');
            customerSelect.trigger('change.select2'); 
        }

        this.form.querySelectorAll('input, select, textarea').forEach(element => {
            element.removeAttribute('readonly');
            element.style.pointerEvents = 'auto';
        });
        
        const submitBtn = this.form.querySelector('input[type="submit"]');
        if (submitBtn) submitBtn.classList.remove('disabled');

        if (this.buttonClass) {
            document.querySelectorAll('.' + this.buttonClass).forEach(button => {
                button.classList.remove('disabled');
                button.style.pointerEvents = 'auto';
            });
        }
    }

    resetOfferData() {

        fetch(`${this.url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log('Server Antwort:', data);
    
            if (data.error) {
                console.error('Fehler vom Server:', data.error);
                return;
            }
    
            const fields = ['designation_number', 'customer', 'valid_until', 'created_by', 'amount', 'company'];
            
            fields.forEach(field => {
                const input = this.form.querySelector(`[name="${field}"]`);
                if (input) input.value = data[field] ?? '';
            });
        })
        .catch(error => {
            console.error('Fetch Fehler:', error);
            alert('Fetch Fehler: ' + error);
        });
    }
}
