

import servicesCreate from './module/serviceCreate'
import serviceUpdate from './module/serviceUpdate'
import serviceChangeHistory from './module/serviceChangeHistory';
import getNextNumerView from '../../../core/static/module/numberRange';

document.addEventListener('DOMContentLoaded', function () {
    const servicecreate = new servicesCreate();
    const serviceupdate = new serviceUpdate();
    const servicechangehistory = new serviceChangeHistory();


    const serviceCreateForm = document.getElementById('serviceCreateForm');
    if(!serviceCreateForm) return;
    getNextNumerView('SER');

}); 