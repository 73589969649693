// agriflow/orders/static/js/orders.js

import CreateOrders from "./module/CreateOrders";
import UpdateOrders from "./module/UpdateOrders";
import ConvertTo from "../../../assets/js/common/ConvertTo";
import PositionView from "../../../assets/js/common/PositionGenrticView";
import CustomerAddressDetail from "../../../assets/js/common/Customer/CustomerAddressDetail";
import ordersChangeHistory from "./module/ordersChangeHistory.js";
import Sidebar from "../../../assets/js/assets/Sidebar.js";
import ActivitiesList from "../../../activities/static/js/module/ActivitiesList.js";



document.addEventListener('DOMContentLoaded', function () {
    const oderscreate = new CreateOrders();
    const updateorders = new UpdateOrders();
    const orderschangehistory = new ordersChangeHistory();

    // Signatur

    const headers = ['Aktion', 'Bezeichnungsnummer', 'Bezeichnung', 'Kosten pro Einheit', 'Steuer', 'Rabatt %', 'Bestellte Menge', 'Gelieferte Menge', 'Einheit', 'Total'];
    const types = {
        'service_positions': {
            'fields': ['id', 'designation_number', 'designation', 'formatted_cost_per_unit', 'formatted_tax', 'formatted_discount', 'pieces', 'delivered_quantity', 'unit', ''],
            'model_type': 'service',
            'rowClass': 'bg-service-table',
            'colspan': 1,
            'app_label': 'orders',
            'model_name': 'OrderServicePosition'
        },
        'object_positions': {
            'fields': ['id', 'designation_number', 'designation'],
            'model_type': 'object',
            'rowClass': 'bg-warning-table',
            'colspans': { 'designation': 8 },
            'app_label': 'orders',
            'model_name': 'OrderObjectPosition'
        },
        'text_positions': {
            'fields': ['id', 'text'],
            'model_type': 'text',
            'rowClass': 'bg-info-table',
            'colspans': { 'text': 9 },
            'app_label': 'orders',
            'model_name': 'OrderTextPosition'
        },
        'machinerys_positions': {
            'fields': ['id', 'designation_number', 'name', 'formatted_cost_per_unit', 'formatted_tax', 'pieces', 'delivered_quantity', 'unit', ''],
            'model_type': 'machinerys',
            'rowClass': 'bg-warning-table',
            'colspans': { 'formatted_tax': 2 },
            'app_label': 'orders',
            'model_name': 'OrderMachinerysPosition'
        },
    };

    const dataContainer = document.querySelector('#dataContainerOrder');
    if(!dataContainer) return
    const dynamicUrl = dataContainer.getAttribute('data-url');
    const view = new PositionView(dynamicUrl, 'dataContainerOrder', headers, types, 'orders');
    view.init();

    // Orders LockManger
    /* const lockOdersUrl = ordersLockManger.replace(/0/, ordersIdVar);
    new FormLockManager('offerUpdateForm', 'lock-class', lockOfferUrl); */

    window.reloadPositionsList = function() {
        view.loadData();
    };

    // Umwandlen in
    const converordertoinvoice = new ConvertTo();
    const button = document.getElementById('copyOrderToInvoice');
    button.addEventListener('click', () => {
        const dataConvertUrl = button.getAttribute('data-convert-url')
        converordertoinvoice.init(dataConvertUrl);
    });

    // Address ansicht
    const customerDetails = document.getElementById('customer-details');
    if(!customerDetails || !CustomerId) return;
    setTimeout(() => {
        const url = customerDetailApi.replace('0', CustomerId);
        CustomerAddressDetail(url);    
    }, 300);  


    // Sidebar
    const sidebarDiv = document.getElementsByClassName('sidebar-open')[0]; 
    if(sidebarDiv){
        const sidebarContainer = new Sidebar(sidebarDiv);
        sidebarContainer.init();
    }

    // Activities ListView
    const activitieslist = new ActivitiesList(activitiesList, 'activities-orders-container');
    window.reloadActivitiesList = function () {
        if (typeof activitieslist !== 'undefined' && activitieslist) {
            activitieslist.init();
        }
    };


});
