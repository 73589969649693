
// agriflow/offers/static/js/offers.js

import OffersCreate from "./module/OffersCreate";
import OfferUpdate from "./module/OffersUpdate";
import ConvertTo from "../../../assets/js/common/ConvertTo";
import PositionView from "../../../assets/js/common/PositionGenrticView";
import CustomerAddressDetail from "../../../assets/js/common/Customer/CustomerAddressDetail";
import OffersChangeHistory from "./module/OffersChangeHistory.js";
import ArchiveManager from "../../../assets/js/common/ArchiveModal.js";
import SignatureForms from "../../../assets/js/common/Signature.js";
import Sidebar from "../../../assets/js/assets/Sidebar.js";
import ActivitiesList from "../../../activities/static/js/module/ActivitiesList.js";
import AmountWatcher from "../../../assets/js/assets/AmountWatcher.js";
import FormLockManager from "../../../assets/js/common/FormLockManager.js"; 


document.addEventListener('DOMContentLoaded', function () {  
    const offercreate = new OffersCreate();
    const offerupdate = new OfferUpdate();
    const offerschangehistory = new OffersChangeHistory();

    // Signatur Save user
    const offerSignatureForm = document.getElementById('offer_signature_form');
    if(offerSignatureForm){
        const signatureave = new SignatureForms();
        signatureave.init('offer_signature_form'); 
    };


    const headers = ['Aktion', 'Bezeichnungsnummer', 'Bezeichnung', 'Kosten pro Einheit', 'Steuer', 'Rabatt %', 'Anzahl', 'Einheit', 'Total'];
    const types = {
        'service_positions': {
            'fields': ['id', 'designation_number', 'designation', 'formatted_cost_per_unit', 'formatted_tax', 'formatted_discount', 'pieces', 'unit', ''],
            'model_type': 'service',
            'rowClass': 'bg-service-table',
            'colspan': 1,
            'app_label': 'offers',
            'model_name': 'OfferServicePosition'
        },
        'object_positions': {
            'fields': ['id', 'designation_number', 'designation'],
            'model_type': 'object',
            'rowClass': 'bg-warning-table',
            'colspans': { 'designation': 7 },
            'app_label': 'offers',
            'model_name': 'OfferObjectPosition'
        },
        'text_positions': {
            'fields': ['id', 'text'],
            'model_type': 'text',
            'rowClass': 'bg-info-table',
            'colspans': { 'text': 8 },
            'app_label': 'offers',
            'model_name': 'OffersTextPosition'
        },
        'machinerys_positions': {
            'fields': ['id', 'designation_number', 'name', 'formatted_cost_per_unit', 'formatted_tax', 'pieces', 'unit', ''],
            'model_type': 'machinerys',
            'rowClass': 'bg-warning-table',
            'colspans': { 'formatted_tax': 2 },
            'app_label': 'offers',
            'model_name': 'OfferMachinerysPositions'
        },
    };

    const dataContainer = document.getElementById('dataContainerOffer');
    if(!dataContainer) return
    const dynamicUrl = dataContainer.getAttribute('data-url');
    const view = new PositionView(dynamicUrl, 'dataContainerOffer', headers, types, 'offers');
    view.init();

    const amountWatcher = new AmountWatcher("#offerUpdateForm", "id_amount");

    // Offers LockManger 
    const lockOfferUrl = offerLockManger.replace(/0/, offersIdVar);
    new FormLockManager('offerUpdateForm', 'lock-class', lockOfferUrl);

    window.reloadPositionsList = async function() {
        view.loadData(); 
    }; 

    // Umwandeln
    const converttoorders = new ConvertTo();
    const button = document.getElementById('copyToOrder');
    button.addEventListener('click', () => {
        const dataConvertUrl = button.getAttribute('data-convert-url')
        converttoorders.init(dataConvertUrl);
    });

    const convertToInvoiceBtn = document.getElementById('copyToInvoice');
    if (convertToInvoiceBtn) {
        convertToInvoiceBtn.addEventListener('click', () => {
            const dataConvertUrl = convertToInvoiceBtn.getAttribute('data-convert-url');
            if (typeof converttoorders !== 'undefined' && converttoorders.init) {
                converttoorders.init(dataConvertUrl);
            } else {
                console.error('converttoorders ist nicht definiert oder hat keine init-Methode');
            }
        });
    }


    // Address View
    const customerDetails = document.getElementById('customer-details');
    if(!customerDetails || !CustomerId) return;
    setTimeout(() => {
        const url = customerDetailApi.replace('0', CustomerId);
        CustomerAddressDetail(url);  
    }, 300);   


    // Pdf Button
    const openPdfOfferButton = document.getElementById('openPdfOfferButton')
    if(!openPdfOfferButton) return
    openPdfOfferButton.addEventListener('click', event => {
        event.preventDefault();
        const pdfUrl = event.currentTarget.getAttribute('data-url');
        var newWindow = window.open(pdfUrl, '_blank');
        if (newWindow) {
            newWindow.focus();
        } 
    })

    // Archivieren
    const archiveBtn = document.getElementById('archiveBtn');
    if(!archiveBtn) return;
    const archiveManager = new ArchiveManager();
    archiveBtn.addEventListener('click', () => {
        const url = archiveBtn.getAttribute('data-archive');
        archiveManager.toggleArchive(url)
    });


    // Sidebar
    const sidebarDiv = document.getElementsByClassName('sidebar-open')[0]; 
    if(sidebarDiv){
        const sidebarContainer = new Sidebar(sidebarDiv);
        sidebarContainer.init();
    }

    // Aktivitäten
    const activitieslist = new ActivitiesList(activitiesList, 'activities-offer-container');
    window.reloadActivitiesList = function () {
        if (typeof activitieslist !== 'undefined' && activitieslist) {
            activitieslist.init();
        }
    };

});


