

import { Modal } from 'bootstrap';
import { BaseAjaxSave } from './shared_service';
import { loadOptions } from './shared_service';
import rendePricelistModal from "../../../price_lists/static/js/module/rendePricelistModal.js";

class ModalsGeneric {
    constructor(){
        this.modalElement = null;
        this.modalInstance = null; 
    }

    init(modalSelector) {
        this.modalElement = document.querySelector(modalSelector);
        if (!this.modalElement) return;
        this.modalInstance = new Modal(this.modalElement,{
            backdrop: 'static'
        });

        const closeButton = this.modalElement.querySelectorAll('.close-modal-button');
        closeButton.forEach(element => {
            element.addEventListener('click', () => {
                this.closeModal();
            })
        });
    }

    async loadModalData(url){
        try {
            const response = await fetch(url);
            if (!response.ok) {
                const errorText = await response.text();
                console.error('Server-Antwort:', errorText);
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            //console.log(data);

            if (data && data.title) {
                const titleElement = this.modalElement.querySelector('.modal-title');
                if (titleElement) {
                    titleElement.textContent = data.title || 'Titel'; 
                }
            }
            if(data && data.btn_primary_name){
                const btnPrimaryName = this.modalElement.querySelector('.btn-primary-name');
                if(btnPrimaryName){
                    btnPrimaryName.classList.remove('d-none');
                    btnPrimaryName.textContent = data.btn_primary_name || 'Neue erstellen'
                    if(data.reload_url_second){
                        btnPrimaryName.setAttribute('data-modal-url', data.reload_url_second);
                    }
                }
            }else {
                const btnPrimaryName = this.modalElement.querySelector('.btn-primary-name');
                if(btnPrimaryName){
                    btnPrimaryName.classList.add('d-none');
                }
            }
            if (data && data.html) {
                const contentElement = this.modalElement.querySelector('.modal-body');
                if (contentElement) {
                    contentElement.innerHTML = data.html || '<p>Kein Inhalt verfügbar</p>';
                    const form = contentElement.querySelector('form'); 
                    form.addEventListener('submit', function(event) {
                        event.preventDefault();
                        BaseAjaxSave(event); 
                    });
                }
            }
            if(data && data.reload_url){
                const closeModalButtons = document.querySelectorAll('.close-modal-button-data');
                if (closeModalButtons.length > 0 && data.reload_url) {
                    closeModalButtons.forEach(button => {
                        button.setAttribute('data-modal-url', data.reload_url);
                    });
                }
            }
            if(data && data.data_options){
                if(data.data_options){
                    loadOptions(data.data_options, this.modalElement);
                }
            }

            const urlParams = new URLSearchParams(url.split('?')[1]); 
            const options = urlParams.get('options');
            if(options){
                const priceList = new rendePricelistModal;   
                priceList.init(CustomerId, options);  
            }

            this.modalInstance.show();
        }  catch (error) {
            console.error('Genauer Fehler:', error);
            console.error('Fehler-Stack:', error.stack);
            console.error('Fehler beim Laden der URL:', url);
        }
    }

    closeModal(){
        if (this.modalInstance) {
            this.modalInstance.hide();
        }
    }

    switchModal(newModalSelector, url){
        this.closeModal();
        this.init(newModalSelector);
        this.loadModalData(url);
    }

}

export default ModalsGeneric;