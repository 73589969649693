

import objektCreate from "./module/objektCreate";
import getNextNumerView from '../../../core/static/module/numberRange.js';
import objectChangeHistory from './module/objectChangeHistory';
import objectUpdate  from './module/objectUpdate.js';

document.addEventListener('DOMContentLoaded', function () {
    const objektcreate = new objektCreate();
    const objectchangehistory = new objectChangeHistory();
    const objectupdate = new objectUpdate();

   const objectFormsCreateBtn = document.getElementById('objectFormsCreate');
   if(!objectFormsCreateBtn) return;
   getNextNumerView('OBJ');
}); 