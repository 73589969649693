
import AlertHandler from '../../../../assets/js/modules/alertHandler'

export default class objectUpdate{

    constructor(){
        this.events();
    }

    events(){
        const objectFormsUpdate = document.querySelector('#objectFormsUpdate');
        if(objectFormsUpdate){
            objectFormsUpdate.addEventListener('submit', this.objectUpdate.bind(this));
        }
    }

    objectUpdate(event){
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);
        const csrfToken = form.querySelector('input[name="csrfmiddlewaretoken"]').value;


        fetch(form.action, {
            method: 'POST',
            headers: {
                'X-CSRFToken': csrfToken
            },
            body: formData
        })
        .then(response => response.json().then(data => ({status: response.status, body: data})))
        .then(({status, body}) => {
            if (status === 200) {
                if (body.success) {
                    
                    AlertHandler.showAlert(body.message, 'success');
                    setTimeout(() => {
                        window.location.href = '/objects/list/'; 
                    }, 300);

                } else {
                    console.log('Fehler beim Bearbeiten des Benutzer!', body.errors);
                }
            } else {
                console.log('Server returned an error:', body.errors);
            }
        })
        .catch(error => {
            console.error('Error:', error);
            AlertHandler.showAlert('Ein Fehler ist aufgetreten: ' + error, 'danger');
        });
    }

}