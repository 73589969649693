import GenericAPI from '../../../../assets/js/modules/GenericAPI';

export default class RenderContact {
    constructor() {
        this.init();
    }

    async init() {

        const container = document.getElementById('contacts-container');
        if(container){
            var newUrl = contactListApi.replace(/0\//, customersId + '/');
            const api = new GenericAPI(newUrl);
            const data = await api.loadData();
            if (data) {
                this.displayData(data.data, data.display_fields);
            }
        }
    }

    getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    async fetchAllPermissions() {
        const accessToken = this.getCookie('access_token');
        
        /* if (!accessToken) {
            console.error('Kein Zugriffstoken vorhanden.');
            window.location.href = logoutView;
            return;
        } */

        const response = await fetch(apiUrl, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            }
        });
        if (response.ok) {
            return await response.json();
        } else {
            console.error('Failed to fetch permissions:', response.statusText);
            return [];
        }
    }

    displayData(data) {
        const container = document.getElementById('contacts-container');

        container.innerHTML = ''
        const row = document.createElement('div'); 
        row.className = 'row';
        
        if (!container) {
            return;
        }

        const accessToken = this.getCookie('access_token');
        /* if (!accessToken) {
            console.error('Kein Zugriffstoken vorhanden.');
            window.location.href = logoutView;
            return;
        } */

        data.forEach(item => {
            const col = document.createElement('div');
            col.className = 'col-12 col-sm-3 mt-3';

            const card = document.createElement('div');
            card.className = 'card';

            const cardBody = document.createElement('div');
            cardBody.classList.add('card-body', 'contact-card');
            
            const contactEditUrl = (id) => contactEdit.replace('0', id);
            const contactDeleteUrl = (id) => contactDelete.replace('0', id);

            cardBody.innerHTML = `
                <div>${item.titel} ${item.firstname ? item.firstname : ''} ${item.lastname}</div>
                <div>${item.email ? item.email : 'Keine Angabe'}</div>
                <div>${item.phone ? item.phone : 'Keine Angabe'}</div>
                ${canUpdateCustomers ? `<div class="edit-modal edit-icon" data-url="${contactEditUrl(item.id)}"><i class="fa-regular fa-pen-to-square"></i></div>` : '' }

                ${canDeleteCustomers ? `<div class="delete-modal" data-url="${contactDeleteUrl(item.id)}"><i class="fa-solid fa-trash-can"></i></div>` : ''}
            `;

            card.appendChild(cardBody);
            col.appendChild(card);
            row.appendChild(col);
        });

        container.appendChild(row);
    }

}